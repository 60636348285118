import { encode, decode } from '@msgpack/msgpack'
import { base64URLStringToBuffer, bufferToBase64URLString } from 'utils'
import { Base64URLString } from 'types/dapp'

export const encodeMsgpack = (data: unknown): Base64URLString =>
  bufferToBase64URLString(
    encode(data, {
      ignoreUndefined: true,
    })
  )

export const decodeMsgpack = <T>(str: Base64URLString): T => {
  const message = base64URLStringToBuffer(str)
  return decode(message) as T
}

export const decodeMsgpackFromBuffer = <T>(buf: Uint8Array): T =>
  decode(buf.buffer) as T

export const encodeMsgpackFromJSON = encode
