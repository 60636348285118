import type { RequireExactlyOne } from 'type-fest'
import type { Transaction as CKBTransaction } from '@ckb-lumos/base/lib/api'
import { CredentialKeyType, SigningAlg } from './server'

export type Base64URLString = string
export interface AuthRequest {
  redirectURL: string
  name?: string
  logo?: string
  challenge?: string
  state?: unknown
}

export enum DappRequestType {
  Auth = 'Auth',
  SignMessage = 'SignMessage',
  TransferCotaNFT = 'TransferCotaNFT',
  TransferMNFT = 'TransferMNFT',
  TransferCKB = 'TransferCKB',
  SignEvm = 'SignEvm',
  SignCkbTx = 'SignCkbTx',
  SignCotaNFT = 'SignCotaNFT',
}

export enum DappCommunicationType {
  Popup = 'popup',
  Redirect = 'redirect',
}

export interface SignMessageResponseData {
  signature: string
  /**
   * The message that was signed by the authenticator,
   * Note that the message is not the original raw message,
   * but is combined with client data and authenticator data
   * according to [WebAuthn Spec](https://www.w3.org/TR/webauthn-2/#sctn-op-get-assertion).
   */
  message: string
  /**
   * The public key used to sign the message
   */
  pubkey: string
  /**
   * The message that was requested to be signed
   */
  challenge: string

  attestation?: string

  keyType: CredentialKeyType

  alg: SigningAlg

  state?: unknown
}

export interface AuthResponseData extends Partial<SignMessageResponseData> {
  address: string
  ethAddress: string
  pubkey: string
  challenge?: string
  message?: string
  keyType: CredentialKeyType
  alg: SigningAlg
}

export interface DappResponse<T> {
  error: string
  data: T
}

export type AuthResponse = {
  type: DappRequestType.Auth
} & RequireExactlyOne<DappResponse<AuthResponseData>, 'data' | 'error'>

export type SignMessageResponse = {
  type: DappRequestType.SignMessage
} & RequireExactlyOne<DappResponse<SignMessageResponseData>, 'data' | 'error'>

export interface BaseSignMessageRequest extends AuthRequest {
  challenge: string
  isData?: boolean
  address?: string
  joyid?: string
}

export interface SignEvmTxResponseData {
  tx: string
}

export type SignEvmResponse = {
  type: DappRequestType.SignEvm
} & RequireExactlyOne<DappResponse<SignEvmTxResponseData>, 'data' | 'error'>

export type SignMessageRequest = RequireExactlyOne<
  BaseSignMessageRequest,
  'address'
>

export interface SignCkbTxResponseData {
  tx: CKBTransaction
}

export type SignCkbTxResponse = {
  type: DappRequestType.SignCkbTx
} & RequireExactlyOne<DappResponse<SignCkbTxResponseData>, 'data' | 'error'>

export type SignCotaNFTResponseData = SignCkbTxResponseData

export type SignCotaNFTResponse = {
  type: DappRequestType.SignCotaNFT
} & RequireExactlyOne<DappResponse<SignCotaNFTResponseData>, 'data' | 'error'>

export const SESSION_KEY_VER = '00'
